<template>
  <div>
    <v-navigation-drawer v-model="drawer" app temporary dark
      src="https://assets.br-gru-1.linodeobjects.com/landing-page/bgDrawer.jpg">
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-img src="https://assets.br-gru-1.linodeobjects.com/landing-page/team_logo.png" alt="Logo"
              max-width="40" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-h6 text-sm-h5">Team Collab</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list dense>
        <v-list-item v-for="([icon, text, link], i) in items" :key="i" link @click="$vuetify.goTo(link)">
          <v-list-item-icon class="justify-center">
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-body-2 text-sm-body-1">{{
              text
              }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app :color="color" :flat="flat" dark hide-on-scroll>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="ml-auto" v-if="isXs" />
      <div class="ml-auto" v-else>
        <v-btn text @click="$vuetify.goTo('#hero')">
          <span class="mr-2">Início</span>
        </v-btn>
        <v-btn text @click="$vuetify.goTo('#features')">
          <span class="mr-2">Serviços</span>
        </v-btn>
        <!--
        <v-btn text @click="$vuetify.goTo('#download')">
          <span class="mr-2">Download</span>
        </v-btn>
        <v-btn text @click="$vuetify.goTo('#pricing')">
          <span class="mr-2">Pricing</span>
        </v-btn>
        -->
        <v-btn rounded outlined text @click="$vuetify.goTo('#contact')">
          <span class="mr-2">Contato</span>
        </v-btn>
      </div>
    </v-app-bar>
  </div>
</template>

<style scoped>
.v-toolbar {
  transition: 0.6s;
}

.expand {
  height: 80px !important;
  padding-top: 10px;
}

.v-app-bar--hidden {
  transform: translateY(-100%);
}

@media (max-width: 600px) {
  .v-btn {
    font-size: 0.875rem;
  }
}
</style>

<script>
export default {
  data: () => ({
    drawer: null,
    isXs: false,
    items: [
      ["mdi-home-outline", "Início", "#hero"],
      ["mdi-information-outline", "Serviços", "#features"],
      /* ["mdi-download-box-outline", "Download", "#download"],
      ["mdi-currency-usd", "Pricing", "#pricing"], */
      ["mdi-email-outline", "Contato", "#contact"],
    ],
  }),
  props: {
    color: String,
    flat: Boolean,
  },
  methods: {
    onResize() {
      this.isXs = window.innerWidth < 850;
    },
  },

  watch: {
    isXs(value) {
      if (!value) {
        if (this.drawer) {
          this.drawer = false;
        }
      }
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
};
</script>
