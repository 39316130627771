<template>
  <section id="hero">
    <v-parallax dark src="https://assets.br-gru-1.linodeobjects.com/landing-page/blockchain.jpg" height="850">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10">
          <v-row align="center" justify="center">
            <v-col cols="12" class="text-center text-sm-left">
              <h1 class="text-h3 text-sm-h2 font-weight-bold mb-4 text-white">Team Collab</h1>
              <h1 class="text-h5 text-sm-h4 font-weight-light">
                Soluções sob medida<p>Resultados que transformam</p>
              </h1>
              <v-btn rounded outlined large dark @click="$vuetify.goTo('#features')" class="mt-5">
                Saiba Mais
                <v-icon class="ml-2">mdi-arrow-down</v-icon>
              </v-btn>
              <!--
              <div class="video d-flex align-center py-4">
                <a @click.stop="dialog = true" class="playBut">
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/" x="0px" y="0px" width="60px"
                    height="60px" viewBox="0 0 213.7 213.7" enable-background="new 0 0 213.7 213.7"
                    xml:space="preserve">
                    <polygon class="triangle" id="XMLID_18_" fill="none" stroke-width="7" stroke-linecap="round"
                      stroke-linejoin="round" stroke-miterlimit="10" points="73.5,62.5 148.5,105.8 73.5,149.1 " />

                    <circle class="circle" id="XMLID_17_" fill="none" stroke-width="7" stroke-linecap="round"
                      stroke-linejoin="round" stroke-miterlimit="10" cx="106.8" cy="106.8" r="103.3" />
                  </svg>
                </a>
                <p class="subheading ml-2 mb-0">Watch the video</p>
              </div>
              -->
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div class="overlay"></div>
      <div id="waves" class="svg-border-waves text-white">
        <v-img src="https://assets.br-gru-1.linodeobjects.com/landing-page/borderWaves.svg" class="wave-image" />
      </div>
    </v-parallax>
    <v-container fluid id="features" class="mt-2">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10">
          <v-row align="center" justify="space-around">
            <v-col cols="12" class="text-center">
              <h1 class="text-h4 text-sm-h3 font-weight-light">Desenvolvimento de Software</h1>
              <h1 class="text-body-1 text-sm-h6 font-weight-light">
                A Team Collab oferece desenvolvimento de software e aplicativos personalizados, sempre focados nas
                necessidades específicas de cada cliente.
              </h1>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="text-center pt-9" v-for="(feature, i) in features" :key="i">
              <v-hover v-slot:default="{ hover }">
                <v-card class="card" shaped :elevation="hover ? 10 : 4" :class="{ up: hover }">
                  <v-img :src="feature.img" max-width="80" class="d-block ml-auto mr-auto"
                    :class="{ 'zoom-efect': hover }"></v-img>
                  <h1 class="text-h6 text-sm-h5 font-weight-regular">{{ feature.title }}</h1>
                  <h4 class="text-body-2 text-sm-subtitle-1 font-weight-regular">
                    {{ feature.text }}
                  </h4>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" max-width="640px">
      <v-card>
        <youtube :video-id="videoId" @ready="ready" @playing="playing"></youtube>
      </v-card>
    </v-dialog>
    <div class="svg-border-waves">
      <img src="https://assets.br-gru-1.linodeobjects.com/landing-page/wave2.svg" />
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      videoId: "i8IvvHJssWE",
      features: [
        {
          img: "https://assets.br-gru-1.linodeobjects.com/landing-page/icon2.png",
          title: "Profissionais Capacitados",
          text: "Equipe formada por especialistas altamente qualificados, com mais de dez anos de experiência no desenvolvimento de software.",
        },
        {
          img: "https://assets.br-gru-1.linodeobjects.com/landing-page/icon1.png",
          title: "Segurança e Estabilidade",
          text: "Desenvolvemos softwares com um compromisso inabalável com estabilidade e segurança.",
        },
        {
          img: "https://assets.br-gru-1.linodeobjects.com/landing-page/icon3.png",
          title: "Software Sob Medida",
          text: "Na Team Collab, entendemos que cada cliente possui necessidades únicas.",
        },
      ],
    };
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.pause();
      }
    },
  },
  methods: {
    ready(event) {
      this.player = event.target;
    },
    playing(event) {
      // The player is playing a video.
    },
    change() {
      // when you change the value, the player will also change.
      // If you would like to change `playerVars`, please change it before you change `videoId`.
      // If `playerVars.autoplay` is 1, `loadVideoById` will be called.
      // If `playerVars.autoplay` is 0, `cueVideoById` will be called.
      this.videoId = "another video id";
    },
    stop() {
      this.player.stopVideo();
    },
    pause() {
      this.player.pauseVideo();
    },
  },
};
</script>

<style>
.btn-play {
  transition: 0.2s;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

#hero {
  z-index: 0;
}

.svg-border-waves img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -2px;
  z-index: -1;
}

.card {
  min-height: 250px;
  padding: 10px;
  transition: 0.5s ease-out;
}

.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}

.card h1 {
  margin-bottom: 10px;
}

.zoom-efect {
  transform: scale(1.1);
}

.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}

@media (max-width: 600px) {
  .card {
    min-height: 200px;
  }

  .v-parallax {
    height: 600px !important;
  }
}
</style>

<style>
section {
  position: relative;
}
</style>
