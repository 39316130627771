<template>
  <v-footer dark padless>
    <v-card flat tile class="secondary white--text text-center">
      <v-card-text>
        <v-btn v-for="(icon, i) in icons" :key="i" class="mx-2 mx-sm-4 white--text" :href="icon.link" target="_blank"
          icon>
          <v-icon size="20" class="text-sm-h6">{{ icon.text }}</v-icon>
        </v-btn>
      </v-card-text>

      <!--  <v-card-text class="white--text pt-0">
        How long are you going to wait before you demand the best for yourself?
      </v-card-text> -->

      <v-divider></v-divider>

      <v-card-text class="white--text text-body-2 text-sm-body-1">
        {{ new Date().getFullYear() }} — <strong>Team Collab</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<style scoped>
.v-card {
  width: 100%;
}

@media (min-width: 600px) {
  .v-icon {
    font-size: 24px !important;
  }
}
</style>

<script>
export default {
  data: () => ({
    icons: [
      /* {
        text: "mdi-facebook",
        link: "",
      },
      {
        text: "mdi-twitter",
        link: "",
      },
      {
        text: "mdi-linkedin",
        link: "",
      },
      {
        text: "mdi-instagram",
        link: "",
      }, */
    ],
  }),
};
</script>
